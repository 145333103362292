import cursor from "./cursor"

const main3d = {

  init() {

    if (window.innerWidth > 1280) {
      cursor.init()
      this.active3d()
      this.cursor()

      $(window).on('3DReady', function() {
        $('.js-loader-canvas').hide();
        $('.js-render-canvas').show();
      });
    }
    else {
      window.flag3d = false
    }

    this.events()
  },

  events() {
    let mobileWindowScrollTop = 0

    $(document).on('click', '.js-modal-stages-open', function() {
      $('#js-modal-stages').addClass('active')
      $('#js-intro-main').addClass('hide')

      if (window.innerWidth <= 767) {
        mobileWindowScrollTop = window.scrollY
        document.documentElement.style.setProperty("--window-height", $(window).outerHeight())

        if (mobileWindowScrollTop > $('.js-header').height() && $('.js-header').hasClass('header_fixed')) {
          $('.js-header').removeClass('header_hide').addClass('header_show')
        }

        $('html, body').css('overflow-y', 'hidden')
        $('html, body').css({
          'height': $(window).outerHeight(),
          '-webkit-touch-action': 'none'
        })
      }
      else {
        if (window.flagInit) {
          canvasInit()
          window.flagInit = false
        }
      }
    })

    $(document).on('click', '.js-modal-stages-close', function() {
      $('#js-modal-stages').removeClass('active')
      $('#js-intro-main').removeClass('hide')

      if (window.innerWidth <= 767) {
        $('html, body').css('overflow-y', 'initial')
        $('html, body').css({
          'height': 'initial',
          '-webkit-touch-action': 'initial'
        })

        window.scrollTo({
          top: mobileWindowScrollTop
        })
      }
      else {
        if (window.flagInit) {
          window.background3d.disableInteractions();
        }
      }
    })

    $(document).on('click', '.js-bannerArrowTop', function() {
      const currentActiveButton = $('.js-stepElem.active');
      const nextButton = currentActiveButton.parent().next().find('.js-stepElem');
      nextButton.click();

      activeIndex3D();
    });

    $(document).on('click', '.js-bannerArrowBottom', function() {
      const currentActiveButton = $('.js-stepElem.active');
      const prevButton = currentActiveButton.parent().prev().find('.js-stepElem');
      prevButton.click();

      activeIndex3D();
    });

    $(document).on('click', '.js-stepElem', function() {
      const _this = $(this);
      const elemIndex = Number(_this.data('index'));

      _this.addClass('active').siblings().removeClass('active');

      if ($(window).width() > 1280) {
        window.background3d.navigateTo(elemIndex);
        activeIndex3D();
      }

    });

  },

  active3d() {
    window.activeIndex3D = function() {
      const active = $('.js-stepElem.active').data('index') + 1;
      const bannerArrowTop = $('.js-bannerArrowTop');
      const bannerArrowBottom = $('.js-bannerArrowBottom');
      const arrowTop = $('.intro-3d__cursor-top .js-bannerNum');
      const arrowBottom = $('.intro-3d__cursor-bottom .js-bannerNum');
      const nextIndex = active + 1;

      if (active + 1 < 6) {
        setTimeout(function() {
          arrowTop.text(active + 1);
        }, 500)
      }

      if (active - 1 !== 0) {
        setTimeout(function() {
          arrowBottom.text(active - 1);
        }, 500)
      }

      if (active < 2) {
        bannerArrowBottom.addClass('disabled');
      } else {
        bannerArrowBottom.removeClass('disabled');
      }

      if (active >= $('.js-stepElem').length) {
        bannerArrowTop.addClass('disabled');
      } else {
        bannerArrowTop.removeClass('disabled');
      }
    }

    activeIndex3D();
  },

  cursor() {
    const cursorDot = $('.js-cursorDot');

    $(document).on('mouseenter', '.js-bannerArrowTop', function() {
      cursorDot.addClass('is_top');
    });

    $(document).on('mouseleave', '.js-bannerArrowTop', function() {
      cursorDot.removeClass('is_top')
    });

    $(document).on('mouseenter', '.js-bannerArrowBottom', function() {
      cursorDot.addClass('is_bottom');
    });

    $(document).on('mouseleave', '.js-bannerArrowBottom', function() {
      cursorDot.removeClass('is_bottom')
    });
  }
}

export default main3d
