const DEFAULT_CAMERA_ZOOM = 0.6

const DEFAULT_ANIMATE_DURATION = 600

const FLOORS = [
  {
    cameraY: 3100,
    zoom: DEFAULT_CAMERA_ZOOM
  },
  {
    cameraY: 2400,
    zoom: DEFAULT_CAMERA_ZOOM
  },
  {
    cameraY: 1850,
    zoom: DEFAULT_CAMERA_ZOOM
  },
  {
    cameraY: 1200,
    zoom: DEFAULT_CAMERA_ZOOM
  },
  {
    cameraY: 0,
    zoom: DEFAULT_CAMERA_ZOOM
  }
]

export {
  DEFAULT_CAMERA_ZOOM,
  DEFAULT_ANIMATE_DURATION,
  FLOORS
}
