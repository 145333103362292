const cursor = {
    delay: 8,
    _x: 0,
    _y: 0,
    endX: (window.innerWidth / 2),
    endY: (window.innerHeight / 2),
    cursorVisible: true,
    cursorEnlarged: false,
    $dot: document.querySelector('.js-cursorDot'),

    init: function() {
        // Set up element sizes
        this.dotSize = this.$dot.offsetWidth;

        this.setupEventListeners();
    },

    setupEventListeners: function() {
        var self = this;

        // Click events
        document.addEventListener('mousedown', function() {
            self.cursorEnlarged = true;
        });
        document.addEventListener('mouseup', function() {
            self.cursorEnlarged = false;
        });

        document.addEventListener('mousemove', function(e) {
            // Show the cursor
            // self.cursorVisible = true;
            self.toggleCursorVisibility();

            // Position the dot
            self.endX = e.pageX;
            self.endY = e.pageY;
            self.$dot.style.top = self.endY + 'px';
            self.$dot.style.left = self.endX + 'px';
        });

        // Hide/show cursor
        document.addEventListener('mouseenter', function(e) {
            // self.cursorVisible = true;
            self.toggleCursorVisibility();
            // self.$dot.style.opacity = 1;
        });

        document.addEventListener('mouseleave', function(e) {
            // self.cursorVisible = true;
            self.toggleCursorVisibility();
            // self.$dot.style.opacity = 0;
        });
    },

    toggleCursorVisibility: function() {
        var self = this;

        // if (self.cursorVisible) {
        //     self.$dot.style.opacity = 1;
        // } else {
        //     self.$dot.style.opacity = 0;
        // }
    }
}

export default cursor
