import Phaser from 'phaser/dist/phaser.min'
import main3d from './home3d/main'
import Home2DScene from './home3d/scenes/Home2DScene'

if (window.innerWidth > 1280) {
  window.flag3d = false
  window.flagInit = true
}

if ($('.js-render-canvas').length > 0) {
  main3d.init();

  window.canvasInit = () => {
    const canvasParent = $('.js-canvas-holder')[0];

    const config = {
      type: Phaser.CANVAS,
      canvas: $('.js-render-canvas')[0],
      transparent: true,
      pixelArt: true,
      scale: {
        mode: Phaser.Scale.NONE,
        width: canvasParent.offsetWidth,
        height: canvasParent.offsetHeight
      },
      scene: [ Home2DScene ]
    };

    const game = new Phaser.Game(config);
  }
}
