import Phaser from 'phaser/dist/phaser.min'
import {
  DEFAULT_CAMERA_ZOOM,
  FLOORS,
  DEFAULT_ANIMATE_DURATION
} from '../common/const'

// const HOME_3D_PATH = '/home3d';

const OFFSET_X_POSITION = -300;
const OFFSET_Y_POSITION = -150;

const DAY_SPRITE = 'home';
const NIGHT_SPRITE = 'home_night';

export default class Home2DScene extends Phaser.Scene {
  constructor() {
    super();

    this._sprite = DAY_SPRITE;

    this._createIoInterface();
  }

  preload() {
    this.load.image(DAY_SPRITE, `${HOME_3D_PATH}/2d-home.png`);
    this.load.image(NIGHT_SPRITE, `${HOME_3D_PATH}/2d-home_night.png`);
  }

  create() {
    this._home2D = this.add.image(0, 0, this._sprite)
      .setOrigin(0);

    this.cameras.main.setRoundPixels(true);
    this.cameras.main.setZoom(DEFAULT_CAMERA_ZOOM);
    this.cameras.main.centerOn(OFFSET_X_POSITION, 0);
    this.cameras.main.setBounds(
      OFFSET_X_POSITION,
      OFFSET_Y_POSITION,
      2160,
      4000
    );

    this._registerEvents();
    this._ready();
  }

  moveCameraToFloor(floorIndex) {
    const camera = this.cameras.main;
    const floor = FLOORS[floorIndex];

    camera.pan(
      OFFSET_X_POSITION,
      floor.cameraY,
      DEFAULT_ANIMATE_DURATION,
      'Quad.easeIn'
    );

    // camera.zoomTo(floor.zoom, DEFAULT_ANIMATE_DURATION);
  }

  _toggleLight() {
    this._sprite = this._sprite === DAY_SPRITE ? NIGHT_SPRITE : DAY_SPRITE;
    this._home2D.setTexture(this._sprite);
  }

  _toggleLightByTime() {
    const hours = (new Date()).getHours();
    if (hours >= 20 || hours <= 6) {
      this._toggleLight();
    }
  }

  _ready() {
    this._toggleLightByTime();
    $(window).trigger('3DReady');
    window.background3d.navigateTo(0);
  }

  _createIoInterface() {
    this._io = {};
    this._io.enableInteractions = () => {};
    this._io.disableInteractions = () => {};
    this._io.navigateTo = this.moveCameraToFloor.bind(this);

    window.background3d = this._io;
  }

  _registerEvents() {
    this.input.on('pointerdown', this._toggleLight.bind(this));
  }
}
